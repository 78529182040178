<template>
	<div class="top_header_plate center_flex_layout" :style="{ background: topBarColor }">
		<div class="center_flex_layout main_top_width">
			<p class="logo_text">OROTATECH</p>

			<div class="center_flex_layout" style="justify-content: flex-start">
				<p
					class="top_tab_text center_flex_layout"
					:class="tabIndex === index ? 'selected_tab_text' : ''"
					v-for="(tabData, index) in tabArrayItem"
					:key="index"
					@click="pageRouter(index)"
				>
					{{ tabData }}
				</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, watch, nextTick } from "vue";

const { proxy } = getCurrentInstance();
import { useRouter, useRoute } from "vue-router";
const tabArrayItem = ref(["首页", "项目服务", "合作客户", "关于我们", "联系我们"]);
const tabIndex = ref(0);
const router = useRouter();
const route = useRoute();
const topBarColor = ref("transparent");

watch(
	() => router.currentRoute.value,
	(newValue) => {
		let deviceWidth = document.documentElement.clientWidth || document.body.clientWidth;
		if (newValue.fullPath.includes("homeMain")) {
			nextTick(() => {
                if (deviceWidth > 600) {
                    topBarColor.value = "transparent";
                } else {
                    topBarColor.value = "#FFFFFF";
                }
                window.addEventListener("scroll", scrollEvent);
			})
		} else {
			nextTick(() => {
                window.removeEventListener("scroll", scrollEvent);
                topBarColor.value = "#FFFFFF";
			})
        }

        if (route.fullPath.includes("homeMain")) {
            tabIndex.value = 0;
        } else if (route.fullPath.includes("service")) {
            tabIndex.value = 1;
        } else if (route.fullPath.includes("cooperation")) {
            tabIndex.value = 2;
        } else if (route.fullPath.includes("About")) {
            tabIndex.value = 3;
        } else if (route.fullPath.includes("contact")) {
            tabIndex.value = 4;
        } else {
            tabIndex.value = 5;
		}
	},
	{ immediate: true }
);

onMounted(() => {
	if (route.fullPath.includes("homeMain")) {
		tabIndex.value = 0;
	} else if (route.fullPath.includes("service")) {
		tabIndex.value = 1;
	} else if (route.fullPath.includes("cooperation")) {
		tabIndex.value = 2;
	} else if (route.fullPath.includes("About")) {
		tabIndex.value = 3;
	} else if (route.fullPath.includes("contact")) {
		tabIndex.value = 4;
	} else {
        tabIndex.value = 5;
    }
});

const scrollEvent = () => {
	let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	let deviceWidth = document.documentElement.clientWidth || document.body.clientWidth;
	if (deviceWidth > 600) {
		if (scrollTop > 70) {
			topBarColor.value = "#FFFFFF";
		} else {
			topBarColor.value = "transparent";
		}
	}
};

const pageRouter = (index) => {
	const pagePath = ["homeMain", "service", "cooperation", "About", "contact"];
	tabIndex.value = index;

	router.push(pagePath[index]);

	window.scrollTo(0, 0);
};
</script>

<style lang="less" scoped>
.top_header_plate {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 999;
	transition: all 0.3s;

	.main_top_width {
		width: 1160px;
		justify-content: space-between;

		@media screen and (max-width: 1279px) {
			width: 90%;
			margin: 0 auto;
		}

		@media screen and (max-width: 899px) {
			justify-content: flex-start;
			flex-flow: row wrap;
		}
	}

	.logo_text {
		font-size: 28px;
		color: #003366;
		font-weight: bold;

		@media screen and (max-width: 1279px) {
			font-size: 24px;
		}

		@media screen and (max-width: 899px) {
			font-size: 16px;
			margin-top: 10px;
			margin-left: 20px;
		}
	}

	.top_tab_text {
		width: 112px;
		height: 70px;
		font-size: 14px;
		color: #102d4d;
		cursor: pointer;
		position: relative;
		transition: all 0.3s;

		@media screen and (max-width: 899px) {
			width: 70px;
			height: 40px;
		}

		&:before {
			content: "";
			width: 44px;
			height: 5px;
			background: #12b1f9;
			position: absolute;
			top: 0;
			left: 32px;
			opacity: 0;

			@media screen and (max-width: 899px) {
				display: none;
			}
		}

		&:hover {
			font-weight: 400;
			color: #12b1f9;

			&:before {
				opacity: 1;
			}
		}
	}

	.selected_tab_text {
		font-weight: 400;
		color: #12b1f9;
	}
}
</style>
