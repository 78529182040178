import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from '../views/Home.vue';
import HomeIndex from '../views/home/index.vue';
import Service from "../views/services/services.vue";
import Cooperation from '../views/cooperation/cooperation.vue'
import Contact from '../views/Contact/Contact.vue'
import About from '../views/About/About.vue'
import Privacy from '../views/Privacy/Privacy.vue'
import NbhdIndex from '../views/nbhd/index.vue'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: NbhdIndex
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
