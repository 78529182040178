<template>
	<div style="position: relative; width: 100vw; height: 248px">
		<img class="footer_backimg" src="../../assets/images/footer_backimg.png" />

		<div class="footer_plate">
			<div class="common_layout">
				<div class="center_flex_layout">
					<p
						class="footer_tab_plate center_flex_layout"
						v-for="(tabData, index) in tabArrayItem"
						:key="index"
						@click="pageRouter(index)"
					>
						{{ tabData }}
					</p>
				</div>

				<div class="center_flex_layout">
					<div>
						<p class="company_info_text">公司名称：上海欧露塔科技有限公司</p>
						<p class="company_info_text">公司地址：上海市闵行区莘松路380号301-09室</p>
						<p class="company_info_text">联系邮件：support@omishow.cn</p>
						<p class="company_info_text">版权所有：上海欧露塔科技有限公司</p>
					</div>
				</div>
			</div>

			<div class="company_bottom_text_plate">
				<div class="center_flex_layout">
					<p class="company_bottom_text" @click="handleJump">
						沪ICP备20003554号-4
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();

const tabArrayItem = ref(["首页", "项目服务", "合作客户", "关于我们", "联系我们", "隐私声明"]);

const pageRouter = (index) => {
	const pagePath = ["homeMain", "service", "cooperation", "About", "contact", "privacy"];

	router.push(pagePath[index]);

	window.scrollTo(0, 0);
};
const handleJump = () => {
	window.open("https://beian.miit.gov.cn/");
};
</script>

<style lang="less" scoped>
.footer_backimg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.footer_plate {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;

	.footer_tab_plate {
		width: 70px;
		height: 60px;
		font-size: 14px;
		color: #ffffff;
		padding: 0 15px;
		cursor: pointer;

		@media screen and (max-width: 899px) {
			font-size: 12px;
		}

		@media screen and (max-width: 599px) {
			padding: 0;
		}

		&:hover {
			font-weight: 400;
			color: #12b1f9;
		}
	}

	.company_info_text {
		font-size: 14px;
		color: rgba(255,255,255, 0.7);
		margin-top: 10px;

		@media screen and (max-width: 899px) {
			font-size: 12px;
			text-align: center;
		}
	}

	.company_bottom_text_plate {
		width: 100%;
		position: absolute;
		bottom: 15px;

		.company_bottom_text {
			font-size: 12px;
			color: #eeeeee;
			text-align: center;
			position: relative;
			cursor: pointer;

			&:before {
				content: '';
				width: 100%;
				height: 1px;
				background: #FFFFFF;
				position: absolute;
				bottom: 0;
				left: 0;
				transform: scale(0);
				transition: all 0.3s;
			}

			&:hover {
				&:before {
					transform: scale(1);
					transition: all 0.3s;
				}
			}
		}
	}
}
</style>
