import axios from 'axios'
import { baseURL } from './global'
import router from '../router/index'
import { ElLoading } from 'element-plus'
import { showToast } from 'vant';
// 创建 axios 实例
axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data);
    if (localStorage.access_token) {
      config.headers.common['Authorization'] = 'Bearer ' + localStorage.access_token
    }
    // config.headers.common['Authorization'] = localStorage.access_token;
    //转loading

    return config
  },
  error => {
    return Promise.reject(error);
  }
)
//响应拦截器
axios.interceptors.response.use(
  response => {
    let status = response.status;
    if (status === 200) {
      if (response.data.code !== '00000') {
        // showToast(response.data.message)
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response.status) {
      let status = error.response.status;
      switch (status) {
        case 401:
          localStorage.setItem('access_token', '')
          if (error.response.config.url.includes('/auth/form')) {
            // showToast(error.response.data.message)
          } else {
            // showToast(error.response.data.message)
            // setTimeout(() => {
            //   router.push({
            //     path: '/login'
            //   })
            // }, 1000)
          }
          break;
        default:
        // showToast('Oops, this is a error message.')
      }
      return Promise.reject(error.response);
    }
  }
)
export function post(url: any, params: any, headers: any, showLoading = true, toast = true) {
  let loading: any
  if (showLoading) {
    loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
  }
  return new Promise((resolve, reject) => {
    axios.post(url, params, {
      headers: headers || {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
      .then(res => {
        if (showLoading) {
          loading.close()
        }
        if (toast && res.data.code !== '00000' && res.data.code !== '20039') {
          showToast(res.data.message);
        }
        resolve(res.data)
      })
      .catch(err => {
        if (showLoading) {
          loading.close()
        }
        reject(err)
      });
  })
}
export function get(url: any, params: any, showLoading = true, toast = true) {
  let loading: any
  if (showLoading) {
    loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
  }
  return new Promise((resolve, reject) => {
    axios.get(url, params)
      .then(res => {
        if (showLoading) {
          loading.close()
        }
        if (toast && res.data.code !== '00000') {
          console.log(res.data.message)
          showToast(res.data.message);
        }
        resolve(res.data)
      }).catch(err => {
        if (showLoading) {
          loading.close()
        }
        reject(err)
      });
  })
}
