<template>
    <div class="requirement_part">
        <div class="common_layout" style="max-width: 960px;">
            <div class="center_flex_layout">
                <p class="requirement_title">提交您的项目需求</p>
            </div>

            <p class="title_line_text">我们会在24小时内联系您</p>

            <div class="center_flex_layout contact_input_width">
                <input v-model="nameVal" class="requirement_input" placeholder="姓名"/>
                <input v-model="contactVal" class="requirement_input" placeholder="联系方式"/>
            </div>

            <div class="center_flex_layout contact_input_width">
                <textarea v-model="contentVal" class="requirement_input" style="height: 126px; margin-top: 10px;" placeholder="请描述您的需求，如：APP开发、小程序开发、H5开发、网站开发等。"/>
            </div>

            <div class="center_flex_layout code_image_plate contact_input_width" style="margin-top: 10px;">
                <input v-model="imageCodeVal" class="requirement_input" style="margin-top: 0; border: none; border-radius: 0;" placeholder="验证码"/>

                <div class="code_image center_flex_layout" @click="getImageCode()">
                    <img id="codeImage" :src="imageCodeUrl"/>
                </div>
            </div>

            <div class="center_flex_layout">
                <div @click="submitRequire()" class="requirement_submit_button">提交</div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { ref, onMounted, getCurrentInstance } from "vue";
    import { post } from '../utils/request.ts';
    import axios from 'axios';
    const {proxy} = getCurrentInstance();

    const nameVal = ref('');
    const contactVal = ref('');
    const contentVal = ref('');
    const imageCodeVal = ref('');
    const imageCodeUrl = ref('');
    const uuidVal = ref('');

    onMounted(() => {
        uuidVal.value = getUid();
        if (uuidVal.value) {
            getImageCode();
        }
    });

    const getUid =()=> {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
    };

    const getImageCode =()=> {
        axios({
            method: 'get',
            url: 'https://test.orotalive.com:8200/image/code?cookie='+uuidVal.value,
            responseType: 'arraybuffer',
            headers: {
                withCredentials: true
            }
        })
            .then(res => {
                // console.log(res);
                let thisImg= window.URL.createObjectURL(new Blob([res.data], {type: 'image/jpeg'}));

                imageCodeUrl.value = thisImg;
            })
            .catch(error => {
                console.log(error);
            })
    };

    const submitRequire =()=> {
        let submitUrl = 'https://test.orotalive.com:8200/official/submit/require';

        if (!nameVal.value) {
            proxy.$toast('请输入您的姓名');
            return;
        }

        if (!contactVal.value) {
            proxy.$toast('请输入您的联系方式');
            return;
        }

        if (!contentVal.value) {
            proxy.$toast('请输入您的需求');
            return;
        }

        if (!imageCodeVal.value) {
            proxy.$toast('请输入验证码');
            return;
        }

        let paramsUrl = submitUrl+'?name='+nameVal.value+'&mobile='+contactVal.value+'&require='+contentVal.value+'&imageCode='+imageCodeVal.value+'&cookie='+uuidVal.value;
        axios({
            method: 'post',
            url: paramsUrl,
            headers: {
                withCredentials: true
            }
        })
            .then(res => {
                // console.log(JSON.stringify(res));
                if (res.data.code === 1) {
                    proxy.$toast('提交成功');
                    nameVal.value = '';
                    contactVal.value = '';
                    contentVal.value = '';
                    imageCodeVal.value = '';
                } else {
                    proxy.$toast(res.data.message);
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

</script>

<style lang="less" scoped>

    .requirement_part {
        width: 100%;
        height: 484px;
        background-image: url("../assets/images/requirement_part_back.png");
        background-repeat: repeat-x;
        background-size: auto;
        background-position: 50% 50%;
        position: relative;
        margin-top: -8px;

        .title_line_text {
            font-size: 16px;
            color: #102d4d;
            text-align: center;
            margin-top: 30px;

            @media screen and (max-width: 899px) {
                font-size: 12px;
                margin: 0 20px;
                margin-top: 15px;
            }
        }

        .requirement_input {
            flex: 1;
            padding: 7px 12px;
            border-radius: 6px;
            border: 1px #dae8ee solid;
            margin-top: 30px;
            font-size: 16px;
            color: #051322;

            @media screen and (max-width: 899px) {
                font-size: 14px;
            }

            &:nth-child(2) {
                margin-left: 10px;

                @media screen and (max-width: 899px) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }

        .code_image_plate {
            border-radius: 6px;
            border: 1px #dae8ee solid;
            background-color: #FFFFFF;
            overflow: hidden;

            .code_image {
                padding: 6px 10px;
                height: 100%;
                border-left: 1px #dae8ee solid;
                cursor: pointer;
            }
        }

        .contact_input_width {
            width: 100%;

            @media screen and (max-width: 1279px) {
                width: 80%;
                margin: 0 auto;
                flex-flow: row wrap;
            }
        }

        .requirement_submit_button {
            width: 100px;
            padding: 7px 0;
            border-radius: 8px;
            background: #12b1f9;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
            margin-top: 10px;

            &:hover {
                opacity: 0.8;
            }
        }
    }

</style>