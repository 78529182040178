import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "animate.css";
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn';
import TopHeader from './components/common/TopHeader.vue';
import Footer from './components/common/Footer.vue';
import RequireMent from './views/RequirementPart.vue';
import { Dialog, showConfirmDialog, showToast, List, PullRefresh, Popup, DatePicker, Calendar, Radio, RadioGroup, Tab, Tabs } from 'vant';
import 'vant/lib/index.css';
import 'animate.css';
const app = createApp(App);

app.component("TopHeader", TopHeader);
app.component('Footer', Footer);
app.component('RequireMent', RequireMent);
app.config.globalProperties.$toast = showToast;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.use(Dialog);
app.use(List);
app.use(PullRefresh);
app.use(Popup);
app.use(DatePicker);
app.use(Calendar);
app.use(Radio);
app.use(RadioGroup);
app.use(Tab);
app.use(Tabs);
app.mount("#app");
