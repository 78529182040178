<template>
	<router-view />
</template>
<script setup></script>
<style lang="less">
@import "@/assets/app.less";
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
html,
body,
#app {
	/*color: #606266;*/
	width: 100%;
	height: 100%;
	font-family: "Microsoft YaHei", "Avenir", Helvetica, Arial, sans-serif;
	overflow: hidden;
	background: #ffffff;
	&::-webkit-scrollbar {
		display: none;
	}
}
</style>
