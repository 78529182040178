<template>
 <div class="page_back center_flex_layout">
  <div class="jj20xnbhd_textline oration_top"></div>
  <img class="jj20xnbhd_center_img" src="../../assets/images/nbhd/jj20xnbhd-center.png" />
  <div class="jj20xnbhd_textline oration_bottom"></div>
 </div>
</template>

<style lang="less" scoped>
.page_back {
 width: 100vw;
 height: 100%;
 background-color: #000000;

 .jj20xnbhd_textline {
  width: 126vw;
  height: 99px;
  position: absolute;
  left: -26vw;
  background-image: url(../../assets/images/nbhd/jj20xnbhd-text-line.png);
  background-size: contain;
  background-repeat: repeat;

  @media screen and (max-width: 500px) {
   width: calc(100vw - 30px);
   height: 18px;
   left: 15px;
   background-image: url(../../assets/images/nbhd/jj20xnbhd-text-mobileline.png);
   background-size: 100%;
   background-repeat: no-repeat;
  }
 }

 .oration_top {
  top: 0;

  @media screen and (max-width: 500px) {
   top: 21px;
  }
 }

 .oration_bottom {
  bottom: 0;

  @media screen and (max-width: 500px) {
   bottom: 21px;
  }
 }

 .jj20xnbhd_center_img {
  width: 20vw;

  @media screen and (max-width: 500px) {
   width: 45vw;
  }
 }
}
</style>